/*
 * Clever Canary
 * https://clevercanary.com/
 *
 * Component displaying 404 (page not found) errors.
 */

// Core dependencies
import React from "react";

// App dependencies
import Layout from "../components/layout";

// Styles
import compStyles from "./404.module.css";
import globalStyles from "../styles/global.module.css";

const NotFoundPage = () => (
        <Layout>
            <div className={globalStyles.wrapper}>
                <div className={compStyles.errorPage}>
                    <h1>Oops!</h1>
                    <p>We can’t find the page you were looking for.</p>
                </div>
            </div>
        </Layout>
);

export default NotFoundPage;
